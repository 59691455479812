var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container advancereceipt" },
    [
      !_vm.isShowEdit
        ? _c("div", { staticClass: "part" }, [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("调拨单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.factoryAllocationCode,
                        callback: function($$v) {
                          _vm.factoryAllocationCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "factoryAllocationCode"
                      }
                    })
                  ],
                  1
                ),
                _vm.activeName !== "Requisition"
                  ? _c(
                      "div",
                      { staticClass: "inline_block mr10 mb10" },
                      [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("预收货清单编号：")
                        ]),
                        _c("el-input", {
                          staticClass: "input_single w200 mr10",
                          attrs: { placeholder: "请输入" },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchHandle($event)
                            }
                          },
                          model: {
                            value: _vm.prepareReceiptCode,
                            callback: function($$v) {
                              _vm.prepareReceiptCode =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "prepareReceiptCode"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                this.activeName == "Requisition"
                  ? _c(
                      "div",
                      { staticClass: "inline_block mr10 mb10" },
                      [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("确认书号：")
                        ]),
                        _c("el-input", {
                          staticClass: "input_single w200 mr10",
                          attrs: { placeholder: "请输入" },
                          nativeOn: {
                            keyup: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.searchHandle($event)
                            }
                          },
                          model: {
                            value: _vm.confirmNumber,
                            callback: function($$v) {
                              _vm.confirmNumber =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "confirmNumber"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [
                      _vm._v("销售订单编号：")
                    ]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.saleOrderId,
                        callback: function($$v) {
                          _vm.saleOrderId =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "saleOrderId"
                      }
                    })
                  ],
                  1
                ),
                this.activeName == "Requisition"
                  ? _c(
                      "div",
                      { staticClass: "inline_block mr10 mb10" },
                      [
                        _c("span", { staticClass: "tag" }, [
                          _vm._v("调拨类型：")
                        ]),
                        _c(
                          "el-select",
                          {
                            staticClass: "select_single w200 mr10",
                            attrs: { placeholder: "请选择" },
                            on: { change: _vm.searchHandle },
                            model: {
                              value: _vm.allocationType,
                              callback: function($$v) {
                                _vm.allocationType = $$v
                              },
                              expression: "allocationType"
                            }
                          },
                          [
                            _c("el-option", {
                              key: "",
                              attrs: { label: "全部", value: "" }
                            }),
                            _vm._l(_vm.transferTypeList, function(item) {
                              return _c("el-option", {
                                key: item.index,
                                attrs: { label: item.name, value: item.index }
                              })
                            })
                          ],
                          2
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "inline_block mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("供应商：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { filterable: "", placeholder: "请选择" },
                        on: { change: _vm.searchHandle },
                        model: {
                          value: _vm.supplierCode,
                          callback: function($$v) {
                            _vm.supplierCode = $$v
                          },
                          expression: "supplierCode"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.supplierList, function(item) {
                          return _c("el-option", {
                            key: item.supplierId,
                            attrs: {
                              label: item.supplierCode,
                              value: item.supplierId
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("创建日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _vm.disabledbo != true
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.excelDownload }
                        },
                        [_vm._v("导出Excel")]
                      )
                    : _vm._e(),
                  _vm.activeName === "Requisition" && _vm.disabledbo != true
                    ? _c(
                        "el-button",
                        {
                          staticClass: "create_btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.createAdvanceReceipt }
                        },
                        [_vm._v("创建预收货清单")]
                      )
                    : _vm._e(),
                  _vm.disabledbo
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.downloadHandle }
                        },
                        [_vm._v("导出Excel")]
                      )
                    : _vm._e(),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _vm.activeName === "Requisition" && _vm.disabledbo != true
                    ? _c("el-button", {
                        attrs: {
                          size: "small",
                          type: "primary",
                          icon: "el-icon-download",
                          circle: "",
                          title: "下载"
                        },
                        on: { click: _vm.downloadHandle }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("div", { staticClass: "table_part clearfix" }, [
              _vm.disabledbo
                ? _c(
                    "div",
                    [
                      _c(
                        "el-tabs",
                        {
                          staticClass: "subnav_wrap",
                          on: { "tab-click": _vm.switchTabHandle },
                          model: {
                            value: _vm.activeName,
                            callback: function($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName"
                          }
                        },
                        _vm._l(_vm.subNavLists, function(item) {
                          return _c(
                            "el-tab-pane",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.tableLoading,
                                  expression: "tableLoading"
                                }
                              ],
                              key: item.value,
                              attrs: { label: item.label, name: item.value }
                            },
                            [
                              item.value == "Requisition"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "multipleTable",
                                          refInFor: true,
                                          staticClass: "table_list",
                                          attrs: {
                                            data: _vm.requisitionList,
                                            "row-key": "id",
                                            "expand-row-keys": _vm.expands,
                                            border: "",
                                            fit: "",
                                            stripe: ""
                                          },
                                          on: {
                                            "row-click": _vm.rowClick,
                                            "expand-change": _vm.expandChange,
                                            select: _vm.selectHandle,
                                            "select-all": _vm.selectAllHandle
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: { type: "selection" }
                                          }),
                                          _c("el-table-column", {
                                            attrs: { type: "expand" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "actions_wrap mb20 clearfix"
                                                        },
                                                        [
                                                          _vm.disabledbo != true
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticClass:
                                                                    "fr",
                                                                  attrs: {
                                                                    type:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.childRequisitionExcelDownload(
                                                                        scope.row
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "导出Excel"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-table",
                                                        {
                                                          ref:
                                                            "childMultipleTable_" +
                                                            scope.row.id,
                                                          refInFor: true,
                                                          staticClass:
                                                            "table_list",
                                                          attrs: {
                                                            data:
                                                              scope.row
                                                                .faItemList,
                                                            border: "",
                                                            fit: ""
                                                          },
                                                          on: {
                                                            select: function(
                                                              selection,
                                                              row
                                                            ) {
                                                              _vm.childSelectHandle(
                                                                selection,
                                                                row,
                                                                scope.row
                                                              )
                                                            },
                                                            "select-all": function(
                                                              selection
                                                            ) {
                                                              _vm.childSelectAllHandle(
                                                                selection,
                                                                scope.row
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                type:
                                                                  "selection"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                label: "行号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.lineNum
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                label:
                                                                  "物料编号",
                                                                "min-width":
                                                                  "180",
                                                                "class-name":
                                                                  "marpno_wuliao",
                                                                "show-overflow-tooltip": true
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.materialCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "确认书号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.confirmNumbers
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "销售订单编号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.saleOrderId
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "调拨单总数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        row.purchaseMeasurementUnit ==
                                                                        "米"
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.allocationPackages
                                                                                      ? row.allocationPackages
                                                                                      : 0) +
                                                                                      "包 " +
                                                                                      (row.allocationQuantityPerPackage
                                                                                        ? row.allocationQuantityPerPackage
                                                                                        : 0) +
                                                                                      "米/包 " +
                                                                                      (row.allocationQuantity
                                                                                        ? row.allocationQuantity
                                                                                        : 0) +
                                                                                      "米"
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.allocationQuantity
                                                                                      ? row.allocationQuantity
                                                                                      : 0) +
                                                                                      (row.purchaseMeasurementUnit
                                                                                        ? row.purchaseMeasurementUnit
                                                                                        : "条")
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "已调拨总数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        row.purchaseMeasurementUnit ==
                                                                        "米"
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.realBagQuantity
                                                                                      ? row.realBagQuantity
                                                                                      : 0) +
                                                                                      "包 " +
                                                                                      (row.realQuantity
                                                                                        ? row.realQuantity
                                                                                        : 0) +
                                                                                      "米"
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.realQuantity
                                                                                      ? row.realQuantity
                                                                                      : 0) +
                                                                                      (row.purchaseMeasurementUnit
                                                                                        ? row.purchaseMeasurementUnit
                                                                                        : "条")
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "已入库总数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        row.purchaseMeasurementUnit ==
                                                                        "米"
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.receivedBagQuantity
                                                                                      ? row.receivedBagQuantity
                                                                                      : 0) +
                                                                                      "包 " +
                                                                                      (row.receivedQuantity
                                                                                        ? row.receivedQuantity
                                                                                        : 0) +
                                                                                      "米"
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.receivedQuantity
                                                                                      ? row.receivedQuantity
                                                                                      : 0) +
                                                                                      (row.purchaseMeasurementUnit
                                                                                        ? row.purchaseMeasurementUnit
                                                                                        : "条")
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "调拨编号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.factoryAllocationCode
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "调拨类型" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.allocationTypeName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "销售订单编号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      row.allocationTypeNameTag ==
                                                      "客订"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.saleOrderId
                                                              )
                                                            )
                                                          ])
                                                        : _c("span")
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "客户名称" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      row.allocationTypeNameTag ==
                                                      "客订"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.customerName
                                                              )
                                                            )
                                                          ])
                                                        : _c("span")
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "供应商" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.supplierShortName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "调拨单创建时间" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.createTime
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "申请人" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.applicantName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading"
                                            }
                                          ],
                                          ref: "multipleTable",
                                          refInFor: true,
                                          staticClass: "table_list",
                                          attrs: {
                                            data: _vm.receiptList,
                                            "row-key": "id",
                                            "expand-row-keys": _vm.expands,
                                            border: "",
                                            fit: "",
                                            stripe: ""
                                          },
                                          on: {
                                            "row-click": _vm.rowClick,
                                            "expand-change": _vm.rowClick
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: { type: "expand" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "actions_wrap mb20 clearfix"
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "fr mr10",
                                                              attrs: {
                                                                type: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.childReceiptExcelDownload(
                                                                    scope.row
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "导出Excel"
                                                              )
                                                            ]
                                                          ),
                                                          scope.row
                                                            .auditStatus ==
                                                            "Draft" ||
                                                          scope.row
                                                            .auditStatus ==
                                                            "Rejected"
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticClass:
                                                                    "fr mr10",
                                                                  attrs: {
                                                                    type:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.editHandle(
                                                                        scope.$index,
                                                                        scope.row
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "更新导入Excel"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "pl-table",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "loading",
                                                              rawName:
                                                                "v-loading",
                                                              value:
                                                                _vm.childListLoading,
                                                              expression:
                                                                "childListLoading"
                                                            }
                                                          ],
                                                          ref:
                                                            "childMultipleTable_" +
                                                            scope.row.id,
                                                          refInFor: true,
                                                          staticClass:
                                                            "table_list",
                                                          staticStyle: {
                                                            width: "100%",
                                                            height: "100%"
                                                          },
                                                          attrs: {
                                                            data:
                                                              scope.row
                                                                .receiptListItemLists,
                                                            "max-height": 700,
                                                            "use-virtual": true,
                                                            "row-height": 90,
                                                            border: "",
                                                            fit: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                label: "行号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.lineNum
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                label:
                                                                  "物料编号",
                                                                "min-width":
                                                                  "180",
                                                                "class-name":
                                                                  "marpno_wuliao",
                                                                "show-overflow-tooltip": true
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.materialCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "调拨单号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.factoryAllocationCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "确认书号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.confirmingCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: "批次号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.batchNumber
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "工厂净长"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.realityNumber
                                                                                ? row.realityNumber
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "工厂毛长"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.grossLength
                                                                                ? row.grossLength
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "调拨数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.transferMeter
                                                                                ? row.transferMeter
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "计算行金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.money
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          scope.row
                                                            .auditStatus ==
                                                          "Approved"
                                                            ? _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "实际行金额"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var row =
                                                                            ref.row
                                                                          return [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  row.revisedMoney
                                                                                ) +
                                                                                "\n                        "
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "采购单价"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.offeredPrice
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "发票单价"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.invoicePrice
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "货款金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.materialAmount
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: "税率"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.vatRate
                                                                                ? row.vatRate
                                                                                : 0
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "增值税金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.vatAmount
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "实际收货数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.realReceiveQuantity
                                                                                ? row.realReceiveQuantity
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "销售订单编号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.saleOrderId
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "最新编辑时间"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.updateTime
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预收货清单编号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.prepareReceiptCode
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "供应商" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.supplierShortName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "已上传箱单数量" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.packingListQuantity
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "工厂发票号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.factoryInvoice
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "币种" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.currencyName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "快递单号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.courierNo
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "创建时间" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.createTime
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "申请人" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.applicantName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "凭证状态" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    var $index = ref.$index
                                                    return [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement: "right",
                                                            width: "300",
                                                            trigger: "hover",
                                                            "open-delay": "1000"
                                                          },
                                                          on: {
                                                            show: function(
                                                              $event
                                                            ) {
                                                              return _vm.checkApprovalProcess(
                                                                row,
                                                                $index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("div", [
                                                            _c(
                                                              "i",
                                                              {
                                                                staticClass:
                                                                  "block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          审批人：\n                          "
                                                                ),
                                                                _c("em", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.fullName
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "i",
                                                              {
                                                                staticClass:
                                                                  "block mt10 mb10"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          审批时间：\n                          "
                                                                ),
                                                                _c("em", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.approvalTime
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "i",
                                                              {
                                                                staticClass:
                                                                  "block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          审批意见：\n                          "
                                                                ),
                                                                _c("em", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.content
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]),
                                                          _c(
                                                            "el-tag",
                                                            {
                                                              staticClass:
                                                                "pointer",
                                                              attrs: {
                                                                slot:
                                                                  "reference",
                                                                type: _vm._f(
                                                                  "statusFilter"
                                                                )(
                                                                  row.auditStatus
                                                                )
                                                              },
                                                              slot: "reference"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  row.auditStatusName
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              width: "170px"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    var $index = ref.$index
                                                    return [
                                                      row.auditStatus ==
                                                        "Approving" ||
                                                      row.auditStatus ==
                                                        "Approved"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-truck",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "关联快递单号"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editCourierNo(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      row.auditStatus ==
                                                        "Draft" ||
                                                      row.auditStatus ==
                                                        "Rejected"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-edit",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "编辑"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-check",
                                                                attrs: {
                                                                  type:
                                                                    "success",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "提交审批"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.submitHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-delete",
                                                                attrs: {
                                                                  type:
                                                                    "danger",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "删除"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-truck",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "关联快递单号"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editCourierNo(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      row.auditStatus ==
                                                      "Terminated"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-truck",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "关联快递单号"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editCourierNo(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-delete",
                                                                attrs: {
                                                                  type:
                                                                    "danger",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "删除"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "el-tabs",
                        {
                          staticClass: "subnav_wrap",
                          on: { "tab-click": _vm.switchTabHandle },
                          model: {
                            value: _vm.activeName,
                            callback: function($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName"
                          }
                        },
                        _vm._l(_vm.subNavList, function(item) {
                          return _c(
                            "el-tab-pane",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.tableLoading,
                                  expression: "tableLoading"
                                }
                              ],
                              key: item.value,
                              attrs: { label: item.label, name: item.value }
                            },
                            [
                              item.value == "Requisition"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          ref: "multipleTable",
                                          refInFor: true,
                                          staticClass: "table_list",
                                          attrs: {
                                            data: _vm.requisitionList,
                                            "row-key": "id",
                                            "expand-row-keys": _vm.expands,
                                            border: "",
                                            fit: "",
                                            stripe: ""
                                          },
                                          on: {
                                            "row-click": _vm.rowClick,
                                            "expand-change": _vm.expandChange,
                                            select: _vm.selectHandle,
                                            "select-all": _vm.selectAllHandle
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: { type: "selection" }
                                          }),
                                          _c("el-table-column", {
                                            attrs: { type: "expand" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "actions_wrap mb20 clearfix"
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass: "fr",
                                                              attrs: {
                                                                type: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.childRequisitionExcelDownload(
                                                                    scope.row
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "导出Excel"
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-table",
                                                        {
                                                          ref:
                                                            "childMultipleTable_" +
                                                            scope.row.id,
                                                          refInFor: true,
                                                          staticClass:
                                                            "table_list",
                                                          attrs: {
                                                            data:
                                                              scope.row
                                                                .faItemList,
                                                            "max-height": 500,
                                                            border: "",
                                                            fit: ""
                                                          },
                                                          on: {
                                                            select: function(
                                                              selection,
                                                              row
                                                            ) {
                                                              _vm.childSelectHandle(
                                                                selection,
                                                                row,
                                                                scope.row
                                                              )
                                                            },
                                                            "select-all": function(
                                                              selection
                                                            ) {
                                                              _vm.childSelectAllHandle(
                                                                selection,
                                                                scope.row
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                type:
                                                                  "selection"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                label: "行号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.lineNum
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                fixed: "",
                                                                label:
                                                                  "物料编号",
                                                                "min-width":
                                                                  "180",
                                                                "class-name":
                                                                  "marpno_wuliao",
                                                                "show-overflow-tooltip": true
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.materialCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "确认书号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.confirmNumbers
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "销售订单编号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.saleOrderId
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "调拨单总数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        row.purchaseMeasurementUnit ==
                                                                        "米"
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.allocationPackages
                                                                                      ? row.allocationPackages
                                                                                      : 0) +
                                                                                      "包 " +
                                                                                      (row.allocationQuantityPerPackage
                                                                                        ? row.allocationQuantityPerPackage
                                                                                        : 0) +
                                                                                      "米/包 " +
                                                                                      (row.allocationQuantity
                                                                                        ? row.allocationQuantity
                                                                                        : 0) +
                                                                                      "米"
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.allocationQuantity
                                                                                      ? row.allocationQuantity
                                                                                      : 0) +
                                                                                      (row.purchaseMeasurementUnit
                                                                                        ? row.purchaseMeasurementUnit
                                                                                        : "条")
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "已调拨总数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        row.purchaseMeasurementUnit ==
                                                                        "米"
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.realBagQuantity
                                                                                      ? row.realBagQuantity
                                                                                      : 0) +
                                                                                      "包 " +
                                                                                      (row.realQuantity
                                                                                        ? row.realQuantity
                                                                                        : 0) +
                                                                                      "米"
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.realQuantity
                                                                                      ? row.realQuantity
                                                                                      : 0) +
                                                                                      (row.purchaseMeasurementUnit
                                                                                        ? row.purchaseMeasurementUnit
                                                                                        : "条")
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "已入库总数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        row.purchaseMeasurementUnit ==
                                                                        "米"
                                                                          ? _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.receivedBagQuantity
                                                                                      ? row.receivedBagQuantity
                                                                                      : 0) +
                                                                                      "包 " +
                                                                                      (row.receivedQuantity
                                                                                        ? row.receivedQuantity
                                                                                        : 0) +
                                                                                      "米"
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    (row.receivedQuantity
                                                                                      ? row.receivedQuantity
                                                                                      : 0) +
                                                                                      (row.purchaseMeasurementUnit
                                                                                        ? row.purchaseMeasurementUnit
                                                                                        : "条")
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: "操作",
                                                                width: "120"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      var $index =
                                                                        ref.$index
                                                                      return [
                                                                        _c(
                                                                          "el-button",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-pie-chart",
                                                                            attrs: {
                                                                              type:
                                                                                "primary",
                                                                              disabled:
                                                                                row.purchaseMeasurementUnit !=
                                                                                "米",
                                                                              circle:
                                                                                "",
                                                                              size:
                                                                                "mini",
                                                                              title:
                                                                                "分解"
                                                                            },
                                                                            on: {
                                                                              click: function(
                                                                                $event
                                                                              ) {
                                                                                $event.stopPropagation()
                                                                                return _vm.decomposeHandle(
                                                                                  row,
                                                                                  $index
                                                                                )
                                                                              }
                                                                            }
                                                                          }
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "调拨编号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.factoryAllocationCode
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "调拨类型" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.allocationTypeName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "销售订单编号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      row.allocationTypeNameTag ==
                                                      "客订"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.saleOrderId
                                                              )
                                                            )
                                                          ])
                                                        : _c("span")
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "客户名称" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      row.allocationTypeNameTag ==
                                                      "客订"
                                                        ? _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                row.customerName
                                                              )
                                                            )
                                                          ])
                                                        : _c("span")
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "供应商" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.supplierShortName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "调拨单创建时间" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.createTime
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "申请人" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.applicantName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "el-table",
                                        {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.listLoading,
                                              expression: "listLoading"
                                            }
                                          ],
                                          ref: "multipleTable",
                                          refInFor: true,
                                          staticClass: "table_list",
                                          attrs: {
                                            data: _vm.receiptList,
                                            "row-key": "id",
                                            "expand-row-keys": _vm.expands,
                                            border: "",
                                            fit: "",
                                            stripe: ""
                                          },
                                          on: {
                                            "row-click": _vm.rowClick,
                                            "expand-change": _vm.rowClick
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: { type: "expand" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(scope) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "actions_wrap mb20 clearfix"
                                                        },
                                                        [
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticClass:
                                                                "fr mr10",
                                                              attrs: {
                                                                type: "primary"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.childReceiptExcelDownload(
                                                                    scope.row
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "导出Excel"
                                                              )
                                                            ]
                                                          ),
                                                          scope.row
                                                            .auditStatus ==
                                                            "Draft" ||
                                                          scope.row
                                                            .auditStatus ==
                                                            "Rejected"
                                                            ? _c(
                                                                "el-button",
                                                                {
                                                                  staticClass:
                                                                    "fr mr10",
                                                                  attrs: {
                                                                    type:
                                                                      "primary"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.editHandle(
                                                                        scope.$index,
                                                                        scope.row
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "更新导入Excel"
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "pl-table",
                                                        {
                                                          ref:
                                                            "childMultipleTable_" +
                                                            scope.row.id,
                                                          refInFor: true,
                                                          staticClass:
                                                            "table_list",
                                                          staticStyle: {
                                                            width: "100%",
                                                            height: "100%"
                                                          },
                                                          attrs: {
                                                            data:
                                                              scope.row
                                                                .receiptListItemLists,
                                                            "max-height": 700,
                                                            "use-virtual": true,
                                                            "row-height": 90,
                                                            border: "",
                                                            fit: ""
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: "行号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.lineNum
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "物料编号",
                                                                "min-width":
                                                                  "180",
                                                                "class-name":
                                                                  "marpno_wuliao",
                                                                "show-overflow-tooltip": true
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.materialCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "调拨单号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.factoryAllocationCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "确认书号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.confirmingCode
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "Dyeing Lot"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.vatNo
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "工厂包号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.batchNumber
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "工厂净长"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.realityNumber
                                                                                ? row.realityNumber
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "工厂毛长"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.grossLength
                                                                                ? row.grossLength
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "调拨数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.transferMeter
                                                                                ? row.transferMeter
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "计算行金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.money
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          scope.row
                                                            .auditStatus ==
                                                          "Approved"
                                                            ? _c(
                                                                "el-table-column",
                                                                {
                                                                  attrs: {
                                                                    label:
                                                                      "实际行金额"
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var row =
                                                                            ref.row
                                                                          return [
                                                                            _vm._v(
                                                                              "\n                          " +
                                                                                _vm._s(
                                                                                  row.revisedMoney
                                                                                ) +
                                                                                "\n                        "
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "采购单价"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.offeredPrice
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "发票单价"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.invoicePrice
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "实际收货数量"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              (row.realReceiveQuantity
                                                                                ? row.realReceiveQuantity
                                                                                : 0) +
                                                                                (row.purchaseMeasurementUnit
                                                                                  ? row.purchaseMeasurementUnit
                                                                                  : "条")
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "货款金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.materialAmount
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "实际货款金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.adjMaterialAmount
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label: "税率"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.vatRate
                                                                                ? row.vatRate
                                                                                : 0
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "实际税率"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.adjVatRate
                                                                                ? row.adjVatRate
                                                                                : 0
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "增值税金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.vatAmount
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "实际增值税金额"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.adjVatAmount
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "销售订单编号"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.saleOrderId
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-table-column",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "最新编辑时间"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var row =
                                                                        ref.row
                                                                      return [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              row.updateTime
                                                                            ) +
                                                                            "\n                        "
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "预收货清单编号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.prepareReceiptCode
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "供应商" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.supplierShortName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "已上传箱单数量" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.packingListQuantity
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "工厂发票号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.factoryInvoice
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "币种" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.currencyName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "快递单号" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.courierNo
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "创建时间" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.createTime
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "申请人" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            row.applicantName
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: { label: "凭证状态" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    var $index = ref.$index
                                                    return [
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement: "right",
                                                            width: "300",
                                                            trigger: "hover",
                                                            "open-delay": "1000"
                                                          },
                                                          on: {
                                                            show: function(
                                                              $event
                                                            ) {
                                                              return _vm.checkApprovalProcess(
                                                                row,
                                                                $index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("div", [
                                                            _c(
                                                              "i",
                                                              {
                                                                staticClass:
                                                                  "block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          审批人：\n                          "
                                                                ),
                                                                _c("em", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.fullName
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "i",
                                                              {
                                                                staticClass:
                                                                  "block mt10 mb10"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          审批时间：\n                          "
                                                                ),
                                                                _c("em", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.approvalTime
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            ),
                                                            _c(
                                                              "i",
                                                              {
                                                                staticClass:
                                                                  "block"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          审批意见：\n                          "
                                                                ),
                                                                _c("em", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      row.content
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          ]),
                                                          _c(
                                                            "el-tag",
                                                            {
                                                              staticClass:
                                                                "pointer",
                                                              attrs: {
                                                                slot:
                                                                  "reference",
                                                                type: _vm._f(
                                                                  "statusFilter"
                                                                )(
                                                                  row.auditStatus
                                                                )
                                                              },
                                                              slot: "reference"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  row.auditStatusName
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "操作",
                                              width: "170px"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function(ref) {
                                                    var row = ref.row
                                                    var $index = ref.$index
                                                    return [
                                                      row.auditStatus ==
                                                      "Approving"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-truck",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "关联快递单号"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editCourierNo(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      row.auditStatus ==
                                                      "Approved"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-truck",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "关联快递单号"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editCourierNo(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-switch-button",
                                                                attrs: {
                                                                  type:
                                                                    "danger",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "关闭"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.closeHandle(
                                                                      $index,
                                                                      row,
                                                                      "close"
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      row.auditStatus == "Close"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-switch-button",
                                                                attrs: {
                                                                  type:
                                                                    "danger",
                                                                  disabled: "",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "关闭"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      row.auditStatus ==
                                                        "Draft" ||
                                                      row.auditStatus ==
                                                        "Rejected"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-edit",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "编辑"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-check",
                                                                attrs: {
                                                                  type:
                                                                    "success",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "提交审批"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.submitHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-delete",
                                                                attrs: {
                                                                  type:
                                                                    "danger",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "删除"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-truck",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "关联快递单号"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editCourierNo(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      row.auditStatus ==
                                                      "Terminated"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-truck",
                                                                attrs: {
                                                                  type:
                                                                    "primary",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title:
                                                                    "关联快递单号"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.editCourierNo(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c("el-button", {
                                                                staticClass:
                                                                  "el-icon-delete",
                                                                attrs: {
                                                                  type:
                                                                    "danger",
                                                                  circle: "",
                                                                  size: "mini",
                                                                  title: "删除"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    $event.stopPropagation()
                                                                    return _vm.deleteHandle(
                                                                      $index,
                                                                      row
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "div",
                { staticClass: "pagination_wrap fr" },
                [
                  _vm.activeName === "Requisition"
                    ? _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.rCurrentPage,
                          "page-sizes": [10, 50],
                          "page-size": _vm.rPageSize,
                          layout: "sizes, total, prev, pager, next",
                          total: _vm.rTotal
                        },
                        on: {
                          "size-change": _vm.rHandleSizeChange,
                          "current-change": _vm.rHandleCurrentChange,
                          "update:currentPage": function($event) {
                            _vm.rCurrentPage = $event
                          },
                          "update:current-page": function($event) {
                            _vm.rCurrentPage = $event
                          }
                        }
                      })
                    : _c("el-pagination", {
                        attrs: {
                          "current-page": _vm.currentPage,
                          "page-sizes": [10, 50],
                          "page-size": _vm.pageSize,
                          layout: "sizes, total, prev, pager, next",
                          total: _vm.total
                        },
                        on: {
                          "size-change": _vm.handleSizeChange,
                          "current-change": _vm.handleCurrentChange,
                          "update:currentPage": function($event) {
                            _vm.currentPage = $event
                          },
                          "update:current-page": function($event) {
                            _vm.currentPage = $event
                          }
                        }
                      })
                ],
                1
              )
            ])
          ])
        : _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.editPartLoading,
                  expression: "editPartLoading"
                }
              ],
              staticClass: "edit_part"
            },
            [
              _c("div", { staticClass: "upload_wrap mb30" }, [
                _c(
                  "div",
                  { staticClass: "display_ib v_top mr20" },
                  [
                    _vm._m(0),
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo ml10 display_ib",
                        attrs: {
                          headers: _vm.headers,
                          action:
                            _vm.uploadUrl +
                            "/infrastructure/v1/attachments/upload",
                          multiple: "",
                          "before-upload": _vm.beforeUploadPackList,
                          "on-exceed": _vm.handleExceedPackList,
                          "on-success": _vm.handleSuccessPackList,
                          "on-remove": _vm.handleRemovePackList,
                          data: { uuid: _vm.editReceiptList.uuid, type: "1" },
                          "file-list": _vm.uploadPackingList,
                          "show-file-list": true
                        }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("上传文件")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip"
                          },
                          [_vm._v("支持扩展名：doc、docx、pdf、jpg")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib v_top" },
                  [
                    _vm._m(1),
                    _c(
                      "el-upload",
                      {
                        staticClass: "upload-demo ml10 display_ib",
                        attrs: {
                          headers: _vm.headers,
                          action:
                            _vm.uploadUrl +
                            "/infrastructure/v1/attachments/upload",
                          multiple: "",
                          "before-upload": _vm.beforeUploadInvoiceList,
                          "on-exceed": _vm.handleExceedInvoiceList,
                          "on-success": _vm.handleSuccessInvoiceList,
                          "on-remove": _vm.handleRemoveInvoiceList,
                          data: { uuid: _vm.editReceiptList.uuid, type: "2" },
                          "file-list": _vm.uploadInvoiceList,
                          "show-file-list": true,
                          limit: 1
                        }
                      },
                      [
                        _c(
                          "el-button",
                          { attrs: { size: "small", type: "primary" } },
                          [_vm._v("上传文件")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "el-upload__tip",
                            attrs: { slot: "tip" },
                            slot: "tip"
                          },
                          [_vm._v("支持扩展名：doc、docx、pdf、jpg")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "clearfix mb10" }, [
                _c("div", { staticClass: "display_ib mr20" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#303133" } },
                    [
                      _vm._v(
                        "供应商：" +
                          _vm._s(
                            _vm.editReceiptList.supplierShortName
                              ? _vm.editReceiptList.supplierShortName
                              : ""
                          )
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "display_ib mr20" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#303133" } },
                    [
                      _vm._v(
                        "上传箱单数量：" +
                          _vm._s(
                            _vm.editReceiptList.packingListQuantity
                              ? _vm.editReceiptList.packingListQuantity
                              : "0"
                          )
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "display_ib mr20" },
                  [
                    _vm._m(2),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10 mb10",
                      attrs: { placeholder: "" },
                      model: {
                        value: _vm.editReceiptList.factoryInvoice,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.editReceiptList,
                            "factoryInvoice",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "editReceiptList.factoryInvoice"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "button inline_block mr10 mt10",
                    staticStyle: { margin: "10 10px" }
                  },
                  [
                    _c("el-button", {
                      attrs: {
                        size: "small",
                        type: "primary",
                        title: "拉取数据",
                        icon: "el-icon-refresh-right",
                        circle: ""
                      },
                      on: { click: _vm.pullDataHandle }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr20" },
                  [
                    _vm._m(3),
                    _c("el-date-picker", {
                      staticClass: "w200",
                      attrs: {
                        size: "middle",
                        "value-format": "yyyy-MM-dd",
                        type: "date",
                        placeholder: "选择日期"
                      },
                      on: { change: _vm.factoryInvoiceTimeChange },
                      model: {
                        value: _vm.editReceiptList.factoryInvoiceTime,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.editReceiptList,
                            "factoryInvoiceTime",
                            $$v
                          )
                        },
                        expression: "editReceiptList.factoryInvoiceTime"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr20" },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: { "font-size": "14px", color: "#303133" }
                      },
                      [_vm._v("付款条件：")]
                    ),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.paymentConditionChange },
                        model: {
                          value: _vm.editReceiptList.paymentTermsV2,
                          callback: function($$v) {
                            _vm.$set(_vm.editReceiptList, "paymentTermsV2", $$v)
                          },
                          expression: "editReceiptList.paymentTermsV2"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择", value: "" }
                        }),
                        _vm._l(_vm.paymentConditionList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr20" },
                  [
                    _vm._m(4),
                    _c("el-date-picker", {
                      staticClass: "w200",
                      attrs: {
                        size: "middle",
                        "value-format": "yyyy-MM-dd",
                        type: "date",
                        placeholder: "选择日期"
                      },
                      on: { input: _vm.paymentTermsChange },
                      model: {
                        value: _vm.editReceiptList.paymentTerms,
                        callback: function($$v) {
                          _vm.$set(_vm.editReceiptList, "paymentTerms", $$v)
                        },
                        expression: "editReceiptList.paymentTerms"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr20" },
                  [
                    _vm._m(5),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.tradeTermsSelect },
                        model: {
                          value: _vm.editReceiptList.tradeTerms,
                          callback: function($$v) {
                            _vm.$set(_vm.editReceiptList, "tradeTerms", $$v)
                          },
                          expression: "editReceiptList.tradeTerms"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择", value: "" }
                        }),
                        _vm._l(_vm.tradeTermsList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr20" },
                  [
                    _vm._m(6),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10 mb10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.currencyChange },
                        model: {
                          value: _vm.editReceiptList.currency,
                          callback: function($$v) {
                            _vm.$set(_vm.editReceiptList, "currency", $$v)
                          },
                          expression: "editReceiptList.currency"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "请选择", value: "" }
                        }),
                        _vm._l(_vm.currencyList, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "display_ib mr20" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#303133" } },
                    [
                      _vm._v(
                        "发票数量总计：" +
                          _vm._s(
                            _vm.editReceiptList.invoiceTotalQuantity
                              ? _vm.editReceiptList.invoiceTotalQuantity
                              : 0
                          )
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "display_ib mr20" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#303133" } },
                    [
                      _vm._v(
                        "货款总金额：" +
                          _vm._s(
                            _vm.editReceiptList.totalMaterialAmount
                              ? _vm.editReceiptList.totalMaterialAmount
                              : 0
                          )
                      )
                    ]
                  )
                ]),
                _c("div", { staticClass: "display_ib mr20" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#303133" } },
                    [
                      _vm._v(
                        "增值税总金额：" +
                          _vm._s(
                            _vm.editReceiptList.totalVatAmount
                              ? _vm.editReceiptList.totalVatAmount
                              : 0
                          )
                      )
                    ]
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "display_ib mr20" },
                  [
                    _vm._m(7),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10 mb10",
                      attrs: { placeholder: "" },
                      nativeOn: {
                        input: function($event) {
                          return _vm.invoiceTotalMoneyInput($event)
                        }
                      },
                      model: {
                        value: _vm.editReceiptList.invoiceTotalMoney,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.editReceiptList,
                            "invoiceTotalMoney",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "editReceiptList.invoiceTotalMoney"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "display_ib mr20" }, [
                  _c(
                    "span",
                    { staticStyle: { "font-size": "14px", color: "#303133" } },
                    [
                      _vm._v(
                        "差额：" +
                          _vm._s(
                            _vm.editReceiptList.invoiceTotalMoneyDifference
                              ? _vm.editReceiptList.invoiceTotalMoneyDifference
                              : 0
                          )
                      )
                    ]
                  )
                ])
              ]),
              _vm.editType === "edit"
                ? _c("upload-excel-component", {
                    staticClass: "mb20",
                    attrs: {
                      "on-success": _vm.importExcelSuccess,
                      "before-upload": _vm.importExcelBeforeUpload
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "edit_wrap hidden" },
                [
                  _c("editTable", {
                    ref: "editTable",
                    attrs: {
                      tableList: _vm.editReceiptList.receiptListItemLists,
                      total:
                        _vm.editReceiptList.receiptListItemLists &&
                        _vm.editReceiptList.receiptListItemLists.length
                          ? _vm.editReceiptList.receiptListItemLists.length
                          : 0,
                      tableLeaderFieldsList: _vm.leaderFieldsList,
                      tableTailFieldsList: _vm.tailFieldsList,
                      tableFieldsList: _vm.editFieldsList
                    },
                    on: {
                      deleteHandle: _vm.deleteEditItemHandle,
                      openHandle: _vm.openEditItemHandle,
                      inputChange: _vm.inputChange,
                      numberInputChange: _vm.numberInputChange
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "edit_btn_wrap mt20 fr" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.clearInvalidHandle($event)
                            }
                          }
                        },
                        [_vm._v("清理无效行")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.createBatchNumberHandle($event)
                            }
                          }
                        },
                        [_vm._v("生成工厂包号")]
                      ),
                      _c("el-button", { on: { click: _vm.cancelEditHandle } }, [
                        _vm._v("取消/关闭")
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isSaveDisabled
                          },
                          on: { click: _vm.saveEditHandle }
                        },
                        [_vm._v("保存")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: _vm.isSubmitDisabled
                          },
                          on: { click: _vm.submitEditHandle }
                        },
                        [_vm._v("提交审批")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.checkpopupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.checkDialogVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.checkDialogVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _c("div", { staticClass: "mb20" }, [
                  _c("span", { staticClass: "mr15" }, [
                    _vm._v(
                      "物料编号：" + _vm._s(_vm.materialFullData.materialCode)
                    )
                  ])
                ]),
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.checkFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.materialFullData.preBatchList, function(
                    item,
                    index
                  ) {
                    return _c(
                      "ul",
                      { key: index, staticClass: "childcontentlist" },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "w20p",
                            attrs: {
                              title: item.confirmingCode
                                ? item.confirmingCode
                                : ""
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.confirmingCode ? item.confirmingCode : ""
                              )
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w20p",
                            attrs: {
                              title: item.batchNumber ? item.batchNumber : ""
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(item.batchNumber ? item.batchNumber : "")
                            )
                          ]
                        ),
                        _c("li", { staticClass: "w15p" }, [
                          item.purchaseMeasurementUnit == "米"
                            ? _c(
                                "div",
                                {
                                  attrs: {
                                    title: item.realityNumber
                                      ? item.realityNumber + "米"
                                      : ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        item.realityNumber
                                          ? item.realityNumber + "米"
                                          : ""
                                      ) +
                                      "\n              "
                                  )
                                ]
                              )
                            : _c("div", [
                                item.purchaseMeasurementUnit != null &&
                                item.purchaseMeasurementUnit != ""
                                  ? _c(
                                      "span",
                                      {
                                        attrs: {
                                          title: item.realityNumber
                                            ? item.realityNumber +
                                              item.purchaseMeasurementUnit
                                            : ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.realityNumber
                                              ? item.realityNumber +
                                                  item.purchaseMeasurementUnit
                                              : ""
                                          )
                                        )
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        attrs: {
                                          title: item.realityNumber
                                            ? item.realityNumber + "条"
                                            : ""
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            item.realityNumber
                                              ? item.realityNumber + "条"
                                              : ""
                                          )
                                        )
                                      ]
                                    )
                              ])
                        ]),
                        _c(
                          "li",
                          {
                            staticClass: "w20p",
                            attrs: {
                              title: item.saleOrderId ? item.saleOrderId : ""
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(item.saleOrderId ? item.saleOrderId : "")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w25p",
                            attrs: {
                              title: item.updateTime ? item.updateTime : ""
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(item.updateTime ? item.updateTime : "")
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.dialogFormVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogFormVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.popupFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c("div", { staticClass: "allparentlist" }, [
                  _c("ul", { staticClass: "childcontentlist" }, [
                    _c("li", { staticClass: "w35p" }, [
                      _c("span", [
                        _vm._v(_vm._s(_vm.addEdit.prepareReceiptCode))
                      ])
                    ]),
                    _c(
                      "li",
                      { staticClass: "w35p" },
                      [
                        _c("el-input", {
                          staticClass: "w200",
                          attrs: {
                            size: "small",
                            maxlength: "100",
                            placeholder: "单行输入"
                          },
                          on: { input: _vm.courierNoChange },
                          model: {
                            value: _vm.addEdit.courierNo,
                            callback: function($$v) {
                              _vm.$set(_vm.addEdit, "courierNo", $$v)
                            },
                            expression: "addEdit.courierNo"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c("el-button", { on: { click: _vm.cancel } }, [
                    _vm._v("取 消")
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.dialogConfirmHandleAdvance }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "导出",
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                visible: _vm.exportDialogVisible
              },
              on: {
                "update:visible": function($event) {
                  _vm.exportDialogVisible = $event
                }
              }
            },
            [
              _c("el-tabs", [
                _c(
                  "ul",
                  { staticClass: "childlist" },
                  _vm._l(_vm.exportFieldList, function(item, index) {
                    return _c("li", { key: index, class: item.class }, [
                      _vm._v(_vm._s(item.name))
                    ])
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "allparentlist" },
                  _vm._l(_vm.childMultipleSelection, function(item, index) {
                    return _c(
                      "ul",
                      { key: index, staticClass: "childcontentlist" },
                      [
                        _c(
                          "li",
                          { staticClass: "w10p", attrs: { title: index + 1 } },
                          [_vm._v(_vm._s(index + 1))]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w30p",
                            attrs: {
                              title: item.factoryAllocationCode
                                ? item.factoryAllocationCode
                                : ""
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.factoryAllocationCode
                                    ? item.factoryAllocationCode
                                    : ""
                                ) +
                                "\n            "
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w30p",
                            attrs: {
                              title: item.supplierName ? item.supplierName : ""
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(item.supplierName ? item.supplierName : "")
                            )
                          ]
                        ),
                        _c(
                          "li",
                          {
                            staticClass: "w30p",
                            attrs: {
                              title: item.allocationTypeName
                                ? item.allocationTypeName
                                : ""
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  item.allocationTypeName
                                    ? item.allocationTypeName
                                    : ""
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "edit_btn_wrap mt10 fr" },
                  [
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.exportDialogVisible = false
                          }
                        }
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmExportHandle }
                      },
                      [_vm._v("确定")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "popup_part openModel" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: this.decomposeVisible ? "分解" : "拆行",
                "close-on-click-modal": false,
                visible: _vm.dialogpopupVisible,
                width: "30%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogpopupVisible = $event
                }
              }
            },
            [
              this.decomposeVisible
                ? _c("div", { staticClass: "openStyle" }, [
                    _c("span", [_vm._v("分解包数")]),
                    _c("span", [_vm._v("分解规格")])
                  ])
                : _c("div", { staticClass: "openStyle" }, [
                    _c("span", [_vm._v("拆出包数")]),
                    _c("span", [_vm._v("拆出规格")])
                  ]),
              _c("div", { staticClass: "openNum" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
                    _c("el-input", {
                      staticClass: "input_single",
                      attrs: {
                        type: "number",
                        disabled: _vm.decomposeVisible,
                        placeholder: "请输入"
                      },
                      nativeOn: {
                        keyup: function($event) {
                          return _vm.proving($event)
                        }
                      },
                      model: {
                        value: _vm.opentransferBagQuantity,
                        callback: function($$v) {
                          _vm.opentransferBagQuantity =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "opentransferBagQuantity"
                      }
                    }),
                    _c("span", [_vm._v("包")])
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
                    this.decomposeVisible
                      ? _c("el-input", {
                          staticClass: "input_single",
                          attrs: {
                            type: "number",
                            onkeyup:
                              "this.value = this.value.replace(/^(\\-)*(\\d+)\\.(\\d\\d).*$/,'$1$2.$3');",
                            placeholder: "请输入"
                          },
                          model: {
                            value: _vm.opentransferMeter,
                            callback: function($$v) {
                              _vm.opentransferMeter =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "opentransferMeter"
                          }
                        })
                      : _c("el-input", {
                          staticClass: "input_single",
                          attrs: {
                            type: "number",
                            onkeyup:
                              "this.value = this.value.replace(/[^\\d.]/g,'');",
                            placeholder: "请输入"
                          },
                          model: {
                            value: _vm.opentransferMeter,
                            callback: function($$v) {
                              _vm.opentransferMeter =
                                typeof $$v === "string" ? $$v.trim() : $$v
                            },
                            expression: "opentransferMeter"
                          }
                        }),
                    _vm.openpurchaseMeasurementUnit == "米"
                      ? _c("span", [_vm._v("米/包")])
                      : _vm._e(),
                    _vm.openpurchaseMeasurementUnit != "米"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.openpurchaseMeasurementUnit
                                ? _vm.openpurchaseMeasurementUnit
                                : "公斤"
                            )
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.dialogpopupVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.savepopoverHandle()
                        }
                      }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "upload_tit display_ib v_top" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("上传箱单")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "upload_tit display_ib v_top" }, [
      _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
      _vm._v("上传工厂发票")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "14px", color: "#303133" } },
      [
        _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
        _vm._v("工厂发票号：")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "14px", color: "#303133" } },
      [
        _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
        _vm._v("工厂发票时间：")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "14px", color: "#303133" } },
      [
        _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
        _vm._v("到期日期：")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "14px", color: "#303133" } },
      [
        _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
        _vm._v("贸易条款：")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "14px", color: "#303133" } },
      [_c("span", { staticClass: "red mr5" }, [_vm._v("*")]), _vm._v("币种：")]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "span",
      { staticStyle: { "font-size": "14px", color: "#303133" } },
      [
        _c("span", { staticClass: "red mr5" }, [_vm._v("*")]),
        _vm._v("发票总金额：")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }